<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getAffiliazioniSecondarie')"
        >
          <div class="row">
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Codice Affiliazione"
                aria-label=""
                :value="codAffiliazioneAffiliazioniSecondarie"
                @input="setCodAffiliazioneAffiliazioniSecondarie"
              />
            </div>
            <div class="col-sm-5 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Denominazione"
                aria-label=""
                :value="denominazioneAffiliazioniSecondarie"
                @input="setDenominazioneAffiliazioniSecondarie"
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                placeholder="Comitato"
                :options="comitati"
                :value="comitatoAffiliazioniSecondarie"
                name="id_periferico"
                @changeSelect="setComitatoAffiliazioniSecondarie($event)"
              />
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-sm-3">
              <SelectInput
                :options="elenco_discipline"
                name="discipline"
                placeholder="Disciplina"
                :value="disciplinaAffiliazioniSecondarie"
                @changeSelect="setDisciplinaAffiliazioniSecondarie($event)"
              />
            </div>
            <div class="col-sm-3">
              <SelectInput
                :options="affiliazioni_stato"
                name="statoSocLookup"
                placeholder="Stato"
                :value="statoAffiliazioniSecondarie"
                @changeSelect="setStatoAffiliazioniSecondarie($event)"
              />
            </div>
            <div class="col-sm-3">
              <div>
                <Datepicker
                  v-model="affiliazioneDalAffiliazioniSecondarie"
                  placeholder="Inizio affiliazione"
                  :format="format"
                  :monthChangeOnScroll="false"
                  :enableTimePicker="false"
                  inputClassName="h-28"
                  @update:modelValue="
                    setAffiliazioneDalAffiliazioniSecondarie($event)
                  "
                  autoApply
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div>
                <Datepicker
                  v-model="affiliazioneAlAffiliazioniSecondarie"
                  placeholder="Fine affiliazione"
                  :format="format"
                  :monthChangeOnScroll="false"
                  :enableTimePicker="false"
                  inputClassName="h-28"
                  @update:modelValue="
                    setAffiliazioneAlAffiliazioniSecondarie($event)
                  "
                  autoApply
                />
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getAffiliazioniSecondarie')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { useFormatDateYMD } from "@/composables/formatDate";
import { format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtri-affiliazioni-economato",
  emits: ["resetFilters", "getAffiliazioniSecondarie"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    Datepicker,
    SelectInput,
  },

  setup() {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const denominazioneAffiliazioniSecondarie = computed(
      () => store.getters.denominazioneAffiliazioniSecondarie
    );
    const codAffiliazioneAffiliazioniSecondarie = computed(
      () => store.getters.codAffiliazioneAffiliazioniSecondarie
    );
    const comitatoAffiliazioniSecondarie = computed(
      () => store.getters.comitatoAffiliazioniSecondarie
    );
    const disciplinaAffiliazioniSecondarie = computed(
      () => store.getters.disciplinaAffiliazioniSecondarie
    );
    const affiliazioneDalAffiliazioniSecondarie = computed(
      () => store.getters.affiliazioneDalAffiliazioniSecondarie
    );
    const affiliazioneAlAffiliazioniSecondarie = computed(
      () => store.getters.affiliazioneAlAffiliazioniSecondarie
    );
    const statoAffiliazioniSecondarie = computed(
      () => store.getters.statoAffiliazioniSecondarie
    );

    const setCodAffiliazioneAffiliazioniSecondarie = (event) => {
      store.commit(
        "setCodAffiliazioneAffiliazioniSecondarie",
        event.target.value
      );
    };
    const setDenominazioneAffiliazioniSecondarie = (event) => {
      store.commit(
        "setDenominazioneAffiliazioniSecondarie",
        event.target.value
      );
    };
    const setComitatoAffiliazioniSecondarie = (event) => {
      store.commit("setComitatoAffiliazioniSecondarie", event);
    };
    const setDisciplinaAffiliazioniSecondarie = (event) => {
      store.commit("setDisciplinaAffiliazioniSecondarie", event);
    };
    const setAffiliazioneDalAffiliazioniSecondarie = (event) => {
      store.commit("setAffiliazioneDalAffiliazioniSecondarie", event);
    };
    const setAffiliazioneAlAffiliazioniSecondarie = (event) => {
      store.commit("setAffiliazioneAlAffiliazioniSecondarie", event);
    };
    const setStatoAffiliazioniSecondarie = (event) => {
      store.commit("setStatoAffiliazioniSecondarie", event);
    };

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const elenco_discipline = computed(() =>
      store.getters.getStateFromName("elenco_discipline")
    );
    const affiliazioni_stato = computed(() =>
      store.getters.getStateFromName("affiliazioni_stato")
    );
    const keys = ref("c|ddc|afs");
    if (
      !comitati.value ||
      !elenco_discipline.value ||
      !affiliazioni_stato.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    return {
      societa: computed(() => store.getters.societaNomeCodAff),
      setComitatoAffiliazioniSecondarie,
      comitati,
      elenco_discipline,
      affiliazioni_stato,
      useFormatDateYMD,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedaffiliazioni_secondarie_list")
      ),
      setDenominazioneAffiliazioniSecondarie,
      setCodAffiliazioneAffiliazioniSecondarie,
      setAffiliazioneAlAffiliazioniSecondarie,
      setAffiliazioneDalAffiliazioniSecondarie,
      setDisciplinaAffiliazioniSecondarie,
      setStatoAffiliazioniSecondarie,
      denominazioneAffiliazioniSecondarie,
      codAffiliazioneAffiliazioniSecondarie,
      comitatoAffiliazioniSecondarie,
      disciplinaAffiliazioniSecondarie,
      affiliazioneAlAffiliazioniSecondarie,
      affiliazioneDalAffiliazioniSecondarie,
      statoAffiliazioniSecondarie,
    };
  },
});
</script>

<style></style>
