<template>
  <div>
    <FiltriAffiliazioniSecondarie
      v-if="isEnabled('fnFiltriAffiliazioniSecondarie')"
      @getAffiliazioniSecondarie="searchAffiliazioniSecondarie"
      @resetFilters="resetFilters"
    />
    <TableAffiliazioniSecondarie
      @getAffiliazioniSecondarie="getAffiliazioniSecondarie"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableAffiliazioniSecondarie from "@/components/components-fit/società/affiliazioni-secondarie/TableAffiliazioniSecondarie.vue";
import FiltriAffiliazioniSecondarie from "@/components/components-fit/società/affiliazioni-secondarie/FiltriAffiliazioniSecondarie.vue";
import { useStore } from "vuex";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "AffiliazioniSecondarie",
  components: {
    TableAffiliazioniSecondarie,
    FiltriAffiliazioniSecondarie,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Affiliazioni Secondarie", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const cod_affiliazione = computed(
      () => store.getters.codAffiliazioneAffiliazioniSecondarie
    );
    const denominazione = computed(
      () => store.getters.denominazioneAffiliazioniSecondarie
    );
    const comitato = computed(
      () => store.getters.comitatoAffiliazioniSecondarie
    );
    const rowsToSkip = computed(
      () => store.getters.rowsToSkipAffiliazioniSecondarie
    );
    const fetchRows = computed(
      () => store.getters.fetchRowsAffiliazioniSecondarie
    );
    const sortColumn = computed(
      () => store.getters.sortColumnAffiliazioniSecondarie
    );
    const sortOrder = computed(
      () => store.getters.sortOrderAffiliazioniSecondarie
    );
    const tutteStagioni = computed(
      () => store.getters.tutteStagioniAffiliazioniSecondarie
    );
    const stagione = computed(() => store.getters.defaultStagione);
    const disciplina = computed(
      () => store.getters.disciplinaAffiliazioniSecondarie
    );
    const affiliazione_dal = computed(
      () => store.getters.affiliazioneDalAffiliazioniSecondarie
    );
    const affiliazione_al = computed(
      () => store.getters.affiliazioneAlAffiliazioniSecondarie
    );
    const stato = computed(() => store.getters.statoAffiliazioniSecondarie);

    const getAffiliazioniSecondarie = () => {
      const localKeys = {
        cod_affiliazione: cod_affiliazione.value,
        denominazione: denominazione.value,
        comitato: comitato.value,
        rowsToSkip: rowsToSkip.value,
        fetchRows: fetchRows.value,
        sortColumn: sortColumn.value,
        sortOrder: sortOrder.value,
        stagione: tutteStagioni.value ? 10000 : stagione.value,
        disciplina: disciplina.value,
        affiliazioneDal: affiliazione_dal.value,
        affiliazioneAl: affiliazione_al.value,
        stato: stato.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.AFFILIAZIONI_SECONDARIE_LIST,
        itemName: "affiliazioni_secondarie_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersAffiliazioniSecondarie");
      getAffiliazioniSecondarie();
    };
    getAffiliazioniSecondarie();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedaffiliazioni_secondarie_list")
    );

    const searchAffiliazioniSecondarie = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipAffiliazioniSecondarie");
      getAffiliazioniSecondarie();
    };
    watch(stagione, () => searchAffiliazioniSecondarie());

    return {
      getAffiliazioniSecondarie,
      resetFilters,
      searchAffiliazioniSecondarie,
      isEnabled,
    };
  },
});
</script>
