<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div>
    <div class="row mt-5 justify-content-between">
      <div class="col-4" v-if="isEnabled('fnAddAffiliazioneSecondariaSocieta')">
        <div>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_affiliazione_tab"
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi affiliazione
            secondaria
          </button>
        </div>
      </div>
      <div
        class="col-4"
        :class="
          isEnabled('fnAddAffiliazioneSecondariaSocieta') ? 'text-center' : ''
        "
      >
        <div class="btn btn-light text-gray-700 fs-6 text-center">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioniAffiliazioniSecondarie"
            :checked="tutteStagioniAffiliazioniSecondarie"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzate
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          affiliazioni di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        affiliazioni_secondarie_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="affiliazioni_secondarie_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsAffiliazioniSecondarie"
        @current-change="setCurrentPageAffiliazioniSecondarie"
      >
        <template v-slot:cell-cod_affiliazione="{ row: data }">
          <router-link
            :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
          >
            {{ data.cod_affiliazione }}</router-link
          >
        </template>
        <template v-slot:cell-anno="{ row: data }">
          <router-link
            :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
          >
            {{ data.anno }}</router-link
          >
        </template>
        <template v-slot:cell-denominazione="{ row: data }"
          ><div class="tab-long">
            <router-link
              :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
            >
              {{ data.denominazione }}</router-link
            >
          </div>
        </template>
        <template v-slot:cell-discipline="{ row: data }">
          <router-link
            :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
          >
            <span v-for="(disciplina, index) in data.discipline" :key="index">
              {{ disciplina.disciplina }}
              <span v-if="index + 1 < data.discipline.length"> - </span>
            </span></router-link
          >
        </template>
        <template v-slot:cell-data_inizio="{ row: data }">
          <router-link
            :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
          >
            {{ data.data_inizio }}</router-link
          >
        </template>
        <template v-slot:cell-saldo="{ row: data }">
          <router-link
            :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
          >
            {{ data.saldo }}</router-link
          >
        </template>
        <template v-slot:cell-creditoApprovare="{ row: data }">
          <router-link
            :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
          >
            {{ data.creditoApprovare }}</router-link
          >
        </template>
        <template v-slot:cell-stato="{ row: data }">
          <router-link
            :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
          >
            <h1
              class="badge"
              :class="
                data.stato === 'affiliazione completata'
                  ? 'badge-light-success'
                  : data.stato === 'richiesta validata'
                  ? 'badge-light-warning'
                  : data.stato === 'in approvazione'
                  ? 'badge-secondary'
                  : data.stato === 'da pagare'
                  ? 'badge-light-primary'
                  : data.stato === 'annullata'
                  ? 'badge-light-danger'
                  : 'badge-light'
              "
            >
              {{ data.stato }}
            </h1></router-link
          >
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <router-link
              :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
              class="btn btn-sm dropdown p-1"
              ><i class="bi bi-eye text-dark fs-5"></i></router-link
            ><button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <div
                type="button"
                @click="
                  selectedIdAffiliazione = data.id_affiliazione;
                  selectedSocieta = data;
                "
                data-bs-toggle="modal"
                data-bs-target="#modal_edit-detail-affiliazione"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-pen text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Modifica Affiliazione
                      </span>
                    </div>
                  </div>
                </li>
              </div>
              <a
                href="#"
                @click="apprMovAffiliazione(data.id_movimento)"
                v-if="
                  !data.data_approvazione &&
                  data.approvabile &&
                  !readOnly &&
                  isEnabled('fnSocietaAffiliazioniApprova')
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check text-dark fs-3"></i>
                      <span class="text-dark ps-1 fs-7"> Approva </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                @click="ratificaAffiliazione(data.id)"
                v-if="
                  !readOnly &&
                  data.ratificabile &&
                  isEnabled('fnSocietaAffiliazioniRatifica')
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check text-dark fs-3"></i>
                      <span class="text-dark ps-1 fs-7"> Ratifica </span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <ModalAddAffiliazione
      :affiliazioneSecondaria="true"
      @loadAffiliazioniSocieta="$emit('getAffiliazioniSecondarie')"
    />
    <EditDetailAffiliazione
      :affiliazioneSecondaria="true"
      :societa="selectedSocieta"
      :idAffiliazione="selectedIdAffiliazione"
      @loadAffiliazioniSocieta="$emit('getAffiliazioniSecondarie')"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import isEnabled from "@/composables/isEnabled.js";
import EditDetailAffiliazione from "@/components/components-fit/società/affiliazioni/EditDetailAffiliazione.vue";
import ModalAddAffiliazione from "@/components/components-fit/società/affiliazioni/ModalAddAffiliazioneTab.vue";

import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import {
  approvaMovAffiliazione,
  ratificaMovAffiliazione,
} from "@/requests/economatoAffiliazioniRequests";

export default {
  name: "AffiliazioniSecondarieTable",
  components: {
    Datatable,
    EditDetailAffiliazione,
    ModalAddAffiliazione,
    Loading,
  },
  emits: ["getAffiliazioniSecondarie", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();
    const selectedSocieta = ref([]);
    const selectedIdAffiliazione = ref([]);

    const tableHeader = ref([
      {
        name: "Codice",
        key: "cod_affiliazione",
      },
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Discipline",
        key: "discipline",
        sortable: false,
      },
      {
        name: "Data affiliazione",
        key: "data_inizio",
      },
      {
        name: "Saldo",
        key: "saldo",
        sortable: false,
      },
      {
        name: "A credito da appr.",
        key: "creditoApprovare",
        sortable: false,
      },
      {
        name: "Stato",
        key: "stato",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const setTutteLeStagioniAffiliazioniSecondarie = () => {
      store.commit("setTutteLeStagioniAffiliazioniSecondarie");
      emit("getAffiliazioniSecondarie");
    };

    const setFetchRowsAffiliazioniSecondarie = (e) => {
      store.commit("setFetchRowsAffiliazioniSecondarie", e);
      emit("getAffiliazioniSecondarie");
    };
    const setCurrentPageAffiliazioniSecondarie = (page) => {
      store.commit("setCurrentPageAffiliazioniSecondarie", page);
      emit("getAffiliazioniSecondarie");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnAffiliazioniSecondarie", columnName);
      store.commit("setSortOrderAffiliazioniSecondarie", order);
      emit("getAffiliazioniSecondarie");
    };

    const isLoading = ref(false);

    const stagioneSelected = computed(() => store.getters.stagioneSelected);

    const apprMovAffiliazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'affiliazione e i relativi movimenti verranno approvati.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Approva",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await approvaMovAffiliazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Movimento approvato correttamente");
              emit("getAffiliazioniSecondarie");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const ratificaAffiliazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'affiliazione verrà ratificata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Ratifica",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await ratificaMovAffiliazione(id, stagioneSelected.value).then(
            (res) => {
              if (res.status == 200) {
                alertSuccess("Affiliazione ratificata correttamente");
                emit("getAffiliazioniSecondarie");
              } else {
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
              isLoading.value = false;
            }
          );
        }
      });
    };

    return {
      tableHeader,
      currentPage: computed(
        () => store.getters.currentPageAffiliazioniSecondarie
      ),
      fetchRows: computed(() => store.getters.fetchRowsAffiliazioniSecondarie),
      rowsToSkip: computed(
        () => store.getters.rowsToSkipAffiliazioniSecondarie
      ),
      sortColumn: computed(
        () => store.getters.sortColumnAffiliazioniSecondarie
      ),
      sortOrder: computed(() => store.getters.sortOrderAffiliazioniSecondarie),
      isEnabled,
      tutteStagioniAffiliazioniSecondarie: computed(
        () => store.getters.tutteStagioniAffiliazioniSecondarie
      ),
      affiliazioni_secondarie_list: computed(() =>
        store.getters.getStateFromName("resultsaffiliazioni_secondarie_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedaffiliazioni_secondarie_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordaffiliazioni_secondarie_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusaffiliazioni_secondarie_list")
      ),

      setTutteLeStagioniAffiliazioniSecondarie,
      setFetchRowsAffiliazioniSecondarie,
      setCurrentPageAffiliazioniSecondarie,
      setSortOrderColumn,

      selectedSocieta,
      selectedIdAffiliazione,

      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      apprMovAffiliazione,
      ratificaAffiliazione,
    };
  },
};
</script>
